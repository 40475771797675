import Constants from '../constants.js'

import AuthUtil from '../authUtil'
import FirestoreUtil from '../firestoreUtil'

import { collection, doc, getDocs, query, where, orderBy, limit, documentId } from "firebase/firestore";

// const db = getFirestore(Globals.firebaseApp);
// console.log(`Loading deciions api = ${JSON.stringify(Globals.firebaseApp)}`)

import axios from 'axios'

export default {
  async updateDecision (decisionId, updates) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUpdateURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}`

    const res = await axios.post(postUpdateURL, updates, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data && res.data.status === "success") {
      return true
    } else {
      return false
    }
  },
  async saveCriteria (decisionId, criteriaList) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postCriteriaURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/criteria`

    const newCriteria = {}
    criteriaList.forEach(criteriaItem => {
      // Not cloning the entire object becuase there are temporary properties like newItem which are just for this session in the UI
      const newItem = {
        // newItem: false,
        name: criteriaItem.name,
        taxon: criteriaItem.taxon,
        userGenerated: criteriaItem.userGenerated || false,
        order: criteriaItem.order,
        ignoreInScrape: criteriaItem.ignoreInScrape,
        keywordOnly: criteriaItem.keywordOnly
      }
      if (typeof criteriaItem.stableValue !== 'undefined') {
        newItem.stableValue = criteriaItem.stableValue
      } else if (criteriaItem.id === 'price') {
        // Price is always considered unstable
        newItem.stableValue = false
      }
      newCriteria[criteriaItem.id] = newItem
    })

    const saveBody = {criteria: newCriteria}

    // Reset local properties
    criteriaList.forEach((item) => {
      item.newItem = false
    })

    return axios.post(postCriteriaURL, saveBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      if (response && response.status === 200 && response.data && response.data.status === "success") {
        return newCriteria
      } else {
        return false
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      return false
    })
  },
  async updateFeaturedDescriptions (decisionId, criteriaDescriptions) {
    // console.log(`Shoudl call endpint for ${decisionId} with new descriptions ${JSON.stringify(criteriaDescriptions)}`)
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postCriteriaDescriptionsEndpoint = `${Constants.API_BASE_PATH}/decisions/${decisionId}/criteriadescriptions`
    const postBody = {
      criteriaDescriptions: criteriaDescriptions
    }

    return axios.post(postCriteriaDescriptionsEndpoint, postBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      if (response && response.status === 200 && response.data && response.data.status === "success") {
        return true
      } else {
        return false
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      return false
    })
  },
  async updateAppFeatureNotes (appKey, taxonId, criteriaNotes) {
    
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    // Get App ID from the key
    const getAppIDEndpoint = `${Constants.API_BASE_PATH}/developer/appkeys/${appKey}/id`

    const appIdResponse = await axios.get(getAppIDEndpoint, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    let response = null
    if (appIdResponse && appIdResponse.status === 200 && appIdResponse.data && appIdResponse.data.appId) {
    
      // const postAppCriteriaNoteEndpoint = `${Constants.API_BASE_PATH}/developer/criterianotes`
      const postAppCriteriaNotesEndpoint = `${Constants.API_BASE_PATH}/developer/apps/${appIdResponse.data.appId}/taxons/${taxonId}/criterianotes`

      const postBody = {
        criteriaNotes: criteriaNotes
      }

      response = await axios.post(postAppCriteriaNotesEndpoint, postBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })
    }

    // handle success
    if (response && response.status === 200 && response.data && response.data.status === "success") {
      return true
    } else {
      return false
    }
  },
  async getAppFeatureNotes (appId, taxonId) {
    
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    
    const getAppCriteriaNotesEndpoint = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/criterianotes`

    const response = await axios.get(getAppCriteriaNotesEndpoint, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    // handle success
    if (response && response.status === 200 && response.data) {
      return response.data
    } else {
      return null
    }
  },
  decisionRef(decisionId) {
    return doc(FirestoreUtil.db, `decisions`, decisionId)
  },
  allDecisionOptionsRef (decisionId) {
    return collection(FirestoreUtil.db, `decisions/${decisionId}/options`)
  },
  decisionResearchDocs (decisionId) {
    return collection(FirestoreUtil.db, `decisions/${decisionId}/researchDocuments`)
  },
  decisionOptionsRef (decisionId, optionIds) {
    return query(collection(FirestoreUtil.db, `decisions/${decisionId}/options`), where(documentId(), 'in', optionIds))
  },
  decisionMembersRef (decisionId, memberIds) {
    return query(collection(FirestoreUtil.db, 'users'), where(documentId(), 'in', memberIds))
  },
  async getPaginatedOptions (decisionId, pageSize, lastOption, orderByField, sortDescending, filterOnNewValues, filterOnUnApprovedOptions, filterOnRequiresReview, filterOnMissingSummary, returnTotal) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let paginatedOptionsURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/?pageSize=${pageSize}`
    // console.log(`Calling pagination with last option being ${JSON.stringify(lastOption)}`)
    if (lastOption) {
      paginatedOptionsURL = `${paginatedOptionsURL}&startAfter=${lastOption.id}`
    }
    if (orderByField) {
      // console.log(`orderByField = ${orderByField}`)
      paginatedOptionsURL = `${paginatedOptionsURL}&sortBy=${orderByField}`
    }
    if (sortDescending) {
      paginatedOptionsURL = `${paginatedOptionsURL}&desc=true`
    }
    if (filterOnNewValues) {
      paginatedOptionsURL = `${paginatedOptionsURL}&filterNewValues=true`
    }
    if (filterOnRequiresReview == true) {
      paginatedOptionsURL = `${paginatedOptionsURL}&requiresReview=true`
    }
    if (filterOnMissingSummary == true) {
      paginatedOptionsURL = `${paginatedOptionsURL}&missingSummary=true`
    }
    if (typeof filterOnUnApprovedOptions === 'boolean') {
      paginatedOptionsURL = `${paginatedOptionsURL}&filterUnApproved=${filterOnUnApprovedOptions}`
    }
    if (typeof returnTotal === 'boolean') {
      paginatedOptionsURL = `${paginatedOptionsURL}&returnTotal=${returnTotal}`
    }
    const res = await axios.get(paginatedOptionsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return {options: [], totalOptionsCount: 0}
    }
  },
  async saveCurrentList (decisionId, listId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postDecisionPhaseURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/phase`
    const body = {
      currentList: listId
    }
    // console.log(`Calling decision post current list ${JSON.stringify(body)}`)
    const res = await axios.post(postDecisionPhaseURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    return res
  },
  async createNewOption (decisionId, optionData) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postNewOptionsURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options`

    const res = await axios.post(postNewOptionsURL, optionData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    return res
  },
  async createNewResearchDoc (decisionId, researchDocData) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postNewOptionsURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/research`

    const res = await axios.post(postNewOptionsURL, researchDocData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async deleteResearchDoc (decisionId, researchDocId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteDocURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/research/${researchDocId}`

    const res = await axios.delete(deleteDocURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data && res.data.status === "success") {
      return true
    } else {
      return false
    }
  },
  async getChosenOption (decisionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const getChosenOptionURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/chosen`
    
    const res = await axios.get(getChosenOptionURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    
    const chosenOptionId = res && res.data ? res && res.data.chosenOption : null;
    return chosenOptionId
  },
  async saveChosenOption (decisionId, chosenOptionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postChooseOptionURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/choose`
    const body = {
      chosenOption: chosenOptionId
    }
    // console.log(`Calling decision choose post ${JSON.stringify(body)}`)
    const res = await axios.post(postChooseOptionURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async setVotingStatus (decisionId, votingClosed) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/votingstatus`
    const body = {
      votingClosed: votingClosed
    }
    const res = await axios.post(postURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async deleteDecision (decisionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}`
    const res = await axios.delete(deleteURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data && res.data.status === 'success') {
      return true
    } else {
      return false
    }
  },
  async saveUsersSortMethod (decisionId, sortMethodId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postChooseOptionURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/sortMethod`
    const body = {
      sortMethod: sortMethodId
    }
    // console.log(`Calling decision choose post ${JSON.stringify(body)}`)
    const response = await axios.post(postChooseOptionURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === "success") {
      return true
    } else {
      return false
    }
  },
  async saveUsersSortCriteria (decisionId, criteriaId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postChooseOptionURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/sortCriteria`
    const body = {
      sortCriteria: criteriaId
    }
    // console.log(`Calling decision choose post ${JSON.stringify(body)}`)
    const response = await axios.post(postChooseOptionURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === "success") {
      return true
    } else {
      return false
    }
  },
  async saveUsersSortAscending (decisionId, isAscending) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postChooseOptionURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/sortAscending`
    const body = {
      sortAscending: isAscending
    }
    // console.log(`Calling decision choose post ${JSON.stringify(body)}`)
    const response = await axios.post(postChooseOptionURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === "success") {
      return true
    } else {
      return false
    }
  },
  async saveCriteriRanges (decisionId, rangeData) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const criteriaRangesURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/criteria/ranges`
    const response = await axios.post(criteriaRangesURL, {ranges: rangeData}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === "success") {
      return true
    } else {
      return false
    }
  },
  async inviteMember (decisionId, memberData) {
    // const shareMemberData = {userName: this.userName, permissions: { canAddOptions: this.canAddOptions }}

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postNewDecisionMemberURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/members`
    const res = await axios.post(postNewDecisionMemberURL, memberData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      },
    });

    if (res && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async resendInvitation (decisionId, memberUserId) {
    // const shareMemberData = {userName: this.userName, permissions: { canAddOptions: this.canAddOptions }}

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postNewDecisionMemberURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/members/${memberUserId}/reinvite`
    const res = await axios.post(postNewDecisionMemberURL, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      },
    });

    if (res && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async removeMember (decisionId, memberId) {
    // const shareMemberData = {userName: this.userName, permissions: { canAddOptions: this.canAddOptions }}

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteMemberURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/members/${memberId}`
    const res = await axios.delete(deleteMemberURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      },
    });

    if (res && res.status === 200 && res.data && res.data.status === 'success') {
      return true
    } else {
      return false
    }
  },
  async getRecommendationsDoc (taxon, taxonRegion, scraperRegion) {
    // console.log(`Getting recommendations for scraper region ${scraperRegion}`)
    let res = null

    const queryConstraints = [where('isSuggestionDoc', '==', true), where('taxonRegion', '==', taxonRegion), where('category.id', '==', taxon)]


    if (scraperRegion) {
      queryConstraints.push(where('scraperRegion', '==', scraperRegion))
    }

    const recommendationsDocQuery = query(collection(FirestoreUtil.db, `decisions`), ...queryConstraints)

    // const recommendationsSnapshot = await recommendationsRef.get()
    const recommendationsSnapshot = await getDocs(recommendationsDocQuery)
    if (!recommendationsSnapshot.empty) {
      res = {
        id: recommendationsSnapshot.docs[0].id,
        ... recommendationsSnapshot.docs[0].data()
      }
    }
    return res
  },
  async getRecommendationByURL (recommendationsDecisionId, url, onlyValid) {
    let res = null
    
    const queryConstraints = [where("urls", "array-contains", url)]
    if (onlyValid) {
      queryConstraints.push(where('contentState.broken', '==', false))
    }

    const recommendationsDocQuery = query(collection(FirestoreUtil.db, `decisions/${recommendationsDecisionId}/options`), ...queryConstraints)

    // TODO filter out the option if onlyValid and it is not substitutefor or approvedRecommendation
    // const recommendationSnapshot = await ref.get()
    const recommendationSnapshot = await getDocs(recommendationsDocQuery)
    if (!recommendationSnapshot.empty) {
      const shapshotData = recommendationSnapshot.docs[0].data()
      if (!onlyValid || (shapshotData && (shapshotData.substituteFor || shapshotData.approvedRecommendation))) {
        res = {
          id: recommendationSnapshot.docs[0].id,
          ... shapshotData
        }
      }
    }
    return res
  },
  recommendedOptionsRef (decisionId, sortMethod, priceRange, pageSize) {
    
    const queryConstraints = [where('approvedRecommendation', '==', true), where('contentState.broken', '==', false)]
    
    // let ref = firebase.firestore().collection("decisions").doc(decisionId).collection("options").where('approvedRecommendation', '==', true).where('contentState.broken', '==', false)
    const havePriceRange = priceRange && Array.isArray(priceRange) && priceRange.length === 2
    if (havePriceRange) {
      // ref = ref.where('latestPrice', '>=', priceRange[0]).where('latestPrice', '<=', priceRange[1])
      queryConstraints.push(where('latestPrice', '>=', priceRange[0]))
      queryConstraints.push(where('latestPrice', '<=', priceRange[1]))
      if (sortMethod && sortMethod.sortField === 'latestPrice') {
        const priceSortDirection = sortMethod.sortAscending ? 'asc' : 'desc'
        // ref = ref.orderBy(sortMethod.sortField, priceSortDirection)
        queryConstraints.push(orderBy(sortMethod.sortField, priceSortDirection))
      } else {
        // ref = ref.orderBy('latestPrice')
        queryConstraints.push(orderBy('latestPrice'))
      }
    }
    if (sortMethod && !(sortMethod.sortField === 'latestPrice' && havePriceRange)) {
      const sortDirection = sortMethod.sortAscending ? 'asc' : 'desc'
      // ref = ref.orderBy(sortMethod.sortField, sortDirection)
      queryConstraints.push(orderBy(sortMethod.sortField, sortDirection))
    }

    return query(collection(FirestoreUtil.db, `decisions/${decisionId}/options`), ...queryConstraints, limit(pageSize))
    // return ref.limit(pageSize)
  },
  recommendedResearchRef (decisionId, pageSize) {
    // let ref = firebase.firestore().collection("decisions").doc(decisionId).collection("researchDocuments")
    // return ref.limit(pageSize)
    return query(collection(FirestoreUtil.db, `decisions/${decisionId}/researchDocuments`), limit(pageSize))
  }
}
