<template>
  <v-menu>
      <template v-slot:activator="{ props }">
        <v-badge
          v-if="invitationsList.length > 0 || assistedDecisions.length > 0"
          :content="invitationsList.length + assistedDecisions.length"
          color="info"
          inline
          ref="notificationBadge"
        >
          <v-icon
            size="small"
            v-bind="props"
          >
            mdi-bell
          </v-icon>
        </v-badge>
      </template>

      <v-card rounded="0" color="primary">
        <v-list>
          <v-list-item v-for="suggestion in assistedDecisions" :key="suggestion.id">
            <PikleSuggestionCard :suggestion="suggestion" @onResponse="handleSuggestionResponse"></PikleSuggestionCard>
          </v-list-item>
          <v-list-item v-for="pikle in invitationsList" :key="pikle.id">
            <PikleInvitationCard :pikleData="pikle" @onResponse="handleInvitationResponse"></PikleInvitationCard>
          </v-list-item>
        </v-list>
      </v-card>
  </v-menu>
</template>

<script>

import * as DecisionsTypes from '@/store/types/decisions'

import PikleInvitationCard from '@/components/Pikles/PikleInvitationCard'
import PikleSuggestionCard from '@/components/Pikles/PikleSuggestionCard'

import { mapState } from 'vuex'

import assistantAPI from '../../api/assistant'

import categoryOptions from '@/mixins/CategoryOptions.js'

export default {
  name: 'InvitesMenu',
  computed: {
    ...mapState({
      invitationsList: state => state.decisions.invitationsList,
      assistedDecisions: state => state.decisions.assistedDecisions,
      currentCountry: state => state.user.currentCountry,
      currentTaxonRegion: state => state.user.currentTaxonRegion
    }),
    notificationCount () {
      return this.invitationsList.length + this.assistedDecisions.length
    }
  },
  mixins: [categoryOptions],
  components: {
    PikleInvitationCard,
    PikleSuggestionCard
  },
  watch: {
    notificationCount: function(newValue, oldValue) {
      // const badge = document.querySelector('.v-badge__badge');
      const badge = this.$refs.notificationBadge
      if (badge && newValue > oldValue) {
        badge.$el.classList.add('notification-shake');
        setTimeout(() => {
          badge.$el.classList.remove('notification-shake');
        }, 1000)
      }
    }
  },
  methods: {
    handleInvitationResponse: async function (response) {
      // console.log(`invitation resonse ${JSON.stringify(response)}`)
      await this.$store.dispatch(`decisions/${DecisionsTypes.ACTION_HANDLE_INVITATION_RESPONSE}`, response)
    },
    handleSuggestionResponse: async function (response) {
      // console.log(`suggestion resonse ${JSON.stringify(response)}`)
      // await this.$store.dispatch(`decisions/${DecisionsTypes.ACTION_HANDLE_SUGGESTION_RESPONSE}`, response)
      const accepted = response.accepted
      const suggestionID = response.suggestion.id
      if (suggestionID && (accepted == true || accepted == false)) {
        // console.log(`suggestionResponse( ${suggestionID}, ${accepted} )`)

        const taxonRegion = this.currentTaxonRegion || null
        const scraperRegion = this.currentCountry || null
        let categoryData = {
          id: 'all',
          name: 'No Category Selected'
        }
        if (response.suggestion.taxon) {
          // console.log(`Set category based on the given taxon ${JSON.stringify(response.suggestion.taxon)}`)
          if (this.hardCodedCategoryOptions && this.hardCodedCategoryOptions.length > 0) {
            const knownCategory = this.hardCodedCategoryOptions.find(c => c.id === response.suggestion.taxon)
            if (knownCategory) {
              // console.log(`knownCategory = ${JSON.stringify(knownCategory)}`)
              categoryData = knownCategory
            }
          }
        }

        await assistantAPI.suggestionResponse(suggestionID, accepted, categoryData, taxonRegion, scraperRegion)
      }
    
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @mixin rotate($turn: 0) {
    transform: rotate($turn);
  }

  @keyframes shake {
    0%, 50% { @include rotate(-0.05turn); }
    25%, 75%, 100% { @include rotate() }
  }

  .notification-shake {
    animation: shake 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-iteration-count: 3;
  }
</style>
