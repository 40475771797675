import Constants from '../constants.js'
import AuthUtil from '../authUtil'

import axios from 'axios'

export default {
  async decisionMessages (decisionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/messages`

    const res = await axios.get(assistantChatURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async decisionChat (decisionId, message, userName, optionNames) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/chat`

    const payload = {
      message: message,
      userName: userName
    }
    if (optionNames && optionNames.length > 0) {
      payload.optionNames = optionNames
    }

    const res = await axios.post(assistantChatURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async optionChat (decisionId, optionId, message, userName, optionNames) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/chat`

    const payload = {
      message: message,
      userName: userName
    }
    if (optionNames && optionNames.length > 0) {
      payload.optionNames = optionNames
    }

    const res = await axios.post(assistantChatURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async decisionFeatureSuggestions (decisionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/featuresuggestions`

    const res = await axios.post(assistantChatURL, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async optionFeatureValueSuggestions (decisionId, optionId, criteria) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/valuesuggestions`

    const res = await axios.post(assistantChatURL, {criteria: criteria}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async getStartedChat (messagePayload) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/getstarted/chat`

    const payload = {
      message: messagePayload.message
    }
    if (messagePayload.taxonOptions && messagePayload.taxonOptions.length > 0) {
      payload.taxons = messagePayload.taxonOptions
    }
    if (messagePayload.decision && messagePayload.decision.name && messagePayload.decision.id) {
      payload.decision = messagePayload.decision
    }

    const res = await axios.post(assistantChatURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async getStartedChatMessages () {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/getstarted/chat`

    const res = await axios.get(assistantChatURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async deleteGetStartedChat () {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const assistantChatURL = `${Constants.ASSISTANT_API_BASE_PATH}/getstarted/chat`

    const res = await axios.delete(assistantChatURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async suggestionResponse (suggestionID, isAccepted, categoryData, taxonRegion, scraperRegion) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const suggestedDecisionURL = `${Constants.ASSISTANT_API_BASE_PATH}/decisions/suggested/${suggestionID}`

    const payload = {
      userApproved: isAccepted,
      taxonRegion: taxonRegion,
      categoryData: categoryData
    }

    if (scraperRegion) {
      payload.scraperRegion = scraperRegion
    }

    const res = await axios.post(suggestedDecisionURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  }
}
