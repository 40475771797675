const CategoryOptions = {
  data () {
    return {
      hardCodedCategoryOptions: [
        {id: 'telescopes', name: 'Telescopes'},
        {id: 'electric-cars', name: 'Electric Cars'},
        {id: 'laptops', name: 'Laptops'}
      ]
    }
  },
}

export default CategoryOptions
