<template>
  <v-skeleton-loader
      v-if="loading"
      type="image"
      width="240"
      class="ma-0 pa-0"
    ></v-skeleton-loader>
  <v-card
    v-else
    :max-width="240"
    outlined
    class="notification-card"
  >
    <v-card-text class="invite-text">
      <h3 class="mb-2 d-flex align-center">
        <v-icon size="sm" class="mr-2">mdi-creation</v-icon>
        Suggestion
      </h3>
      <div v-if="suggestion && suggestion.name">
        <div class="mb-2">Would you like to create the suggested decision board...</div>
        <div class="mb-2">
          <h3>{{ suggestion.name }}</h3>
          <div v-if="Array.isArray(suggestion.criteria) && suggestion.criteria.length > 0">
            Focusing on <b>{{ suggestion.criteria.map(c => c.name).join(', ') }}</b>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="success"
        :disabled="responding"
        :loading="respondingTrue"
        @click="handleSuggestionResponse(true)"
      >
        Accept
      </v-btn>
      <v-btn
        text
        color="error"
        :disabled="responding"
        :loading="respondingFalse"
        @click="handleSuggestionResponse(false)"
      >
        Dismiss
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

export default {
  name: 'PikleSuggestionCard',
  props: {
    suggestion: Object
  },
  data () {
    return {
      respondingTrue: false,
      respondingFalse: false,
      loading: false
    }
  },
  emits: ['onResponse'],
  computed: {
    responding () {
      return this.respondingTrue || this.respondingFalse
    },
  },
  methods: {
    handleSuggestionResponse: function (accepted) {
      this.respondingTrue = accepted
      this.respondingFalse = !accepted
      this.$emit('onResponse', {suggestion: this.suggestion, accepted: accepted})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-outer {
  position: relative;
  padding-bottom: 50px;
  &:hover {
    background-color: #EEE;
  }
}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-owner-avatar {
  position: absolute;
  right: -10px;
  top: -10px;
}
.owners-section {
  margin-top: 10px;
  min-height: 20px;
}
</style>
