import { onSnapshot } from "firebase/firestore";

import decisionsAPI from '../../api/decisions'

import * as types from '../types/decisions'
import * as commonTypes from '../types/common'
import slug from 'slug'
// import Vue from 'vue'

export const getDefaultState = () => ({
  decisions: [],
  invitationsList: [],
  assistedDecisions: [],
  sharedOwners: {},
  fetchingStatus: false,
  laodingUsersDecision: false,
  laodingSharedDecision: false,
  sortAscending: true,
  newDialogOpen: false,
  newDecisionLinkedOption: null,
  dataUnSubscribeCallback: null,
  sharedDataUnSubscribeCallback: null,
  assistedDecisionsUnsubscribeCallback: null
})

// initial state
const state = getDefaultState()

// getters
const getters = {
  decisionCount (state) {
    return state.decisions.length
  },
  slugIdMap (state) {
    const res = {}
    state.decisions.forEach((item) => {
      const nameSlug = slug(item.name)
      res[`${item.owner}-${nameSlug}`] = item.id
    })
    return res
  },
  usedNames (state) {
    let res = []
    if (state.decisions) {
      res = state.decisions.map((item) => {
        return item.name
      })
    }
    return res
  },
  sorted (state) {
		const items = [...state.decisions].sort((a, b) => {
      const aSeconds = a.createdAt ? a.createdAt.seconds || a.createdAt._seconds || 0 : 0;
      const bSeconds = b.createdAt ? b.createdAt.seconds || b.createdAt._seconds || 0 : 0;
      return bSeconds - aSeconds
    })

		if(!state.sortAscending){
			items.reverse()
		}

		return items
	}
}

// actions
const actions = {
  [commonTypes.ACTION_RESET_STATE]: ({ commit }) => {
    // Unsubscribe from any data listeners
    commit(types.MUTATION_SET_UNSUBSCRIBE_CALLBACK, null)
    commit(types.MUTATION_SET_SHARED_UNSUBSCRIBE_CALLBACK, null)
    commit(commonTypes.MUTATION_RESET_STATE)
  },
  [types.ACTION_LOAD_DECISIONS_MAP]: async ({ commit }) => {

    commit(types.MUTATION_SET_DECISIONS, [])
    commit(types.MUTATION_FETCHING_STATUS, true)
    const decisionsMap = await decisionsAPI.getDecisionsMap();//.then(decisionsMap => {
    if (decisionsMap) {
      const decisionList = []
      Object.keys(decisionsMap).forEach((key) => {
        decisionList.push({id: key, ...decisionsMap[key]})
      });
      commit(types.MUTATION_SET_DECISIONS, decisionList)
      commit(types.MUTATION_FETCHING_STATUS, false)
    }
    return decisionsMap
    // });
  },
  [types.ACTION_LOAD_DECISIONS]: ({ state, commit }) => {

    if (!state.dataUnSubscribeCallback) {
      // commit(types.MUTATION_SET_DECISIONS, [])
      commit(types.MUTATION_LOADING_USERS_DECISIONS, true)

      const unsubscribe = onSnapshot(decisionsAPI.usersDecisionsRef(), (snapshot) => {
        commit(types.MUTATION_LOADING_USERS_DECISIONS, false)
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            commit(types.MUTATION_ADD_DECISION, {id: change.doc.id, ...change.doc.data()})
          }
          if (change.type === "modified") {
            commit(types.MUTATION_UPDATE_DECISION, {id: change.doc.id, updates: change.doc.data()})
          }
          if (change.type === "removed") {
            commit(types.MUTATION_REMOVE_DECISION, change.doc.id)
          }
        });
      });

      commit(types.MUTATION_SET_UNSUBSCRIBE_CALLBACK, unsubscribe)
    }
  },
  [types.ACTION_LOAD_SHARED_DECISIONS]: ({ state, commit }) => {

    if (!state.sharedDataUnSubscribeCallback) {
      // commit(types.MUTATION_SET_DECISIONS, [])
      commit(types.MUTATION_LOADING_SHARED_DECISIONS, true)

      const unsubscribe = onSnapshot(decisionsAPI.sharedDecisionsRef(), (snapshot) => {
        commit(types.MUTATION_LOADING_SHARED_DECISIONS, false)
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            commit(types.MUTATION_ADD_DECISION, {id: change.doc.id, ...change.doc.data()})
          }
          if (change.type === "modified") {
            commit(types.MUTATION_UPDATE_DECISION, {id: change.doc.id, updates: change.doc.data()})
          }
          if (change.type === "removed") {
            commit(types.MUTATION_REMOVE_DECISION, change.doc.id)
          }
        });
      });

      commit(types.MUTATION_SET_SHARED_UNSUBSCRIBE_CALLBACK, unsubscribe)
    }

    if (!state.assistedDecisionsUnsubscribeCallback) {
      const unsubscribe = onSnapshot(decisionsAPI.assistedDecisionsRef(), (snapshot) => {
        const assistedDecisions = []
        snapshot.forEach((doc) => {
          assistedDecisions.push({id: doc.id, ...doc.data()})
        })
        commit(types.MUTATION_SET_ASSISTED_DECISIONS, assistedDecisions)
      })
      
      commit(types.MUTATION_SET_ASSISTED_DECISIONS_UNSUBSCRIBE_CALLBACK, unsubscribe)
    }
  },
  [types.ACTION_CREATE_NEW_DECISION]: async ({ commit }, payload) => {

    // console.log('Creating a new decision in the store')
    const tempId = '_' + Math.random().toString(36).substr(2, 9) // Random temporary ID. Will be used to remove the placeholder
    const placeholderData = {id: tempId, loading: true, name: payload.name}
    commit(types.MUTATION_ADD_DECISION, placeholderData)

    const newDecisionResponse = await decisionsAPI.createNewDecision(payload.name, payload.taxonRegion, payload.scraperRegion, payload.criteria, payload.criteriaValueRanges, payload.imgSrc, payload.enabledBy)

    // Remove the placeholder
    commit(types.MUTATION_REMOVE_DECISION, placeholderData.id)

    if (newDecisionResponse) {
      const newDecisionId = newDecisionResponse.decisionId
      // Set the decision taxon if one was selected
      if (payload.categoryData) {
        const setCategoryPayload = {taxonRegion: payload.taxonRegion, ...payload.categoryData}
        await decisionsAPI.setDecisionCategory(newDecisionId, setCategoryPayload)
      }
    }

    return newDecisionResponse
  },
  async [types.ACTION_LOAD_INVITATIONS] ({ commit }) {
    commit(types.MUTATION_FETCHING_STATUS, true)
    const invitiations = await decisionsAPI.getInvitations()
    if (invitiations) {
      commit(types.MUTATION_SET_INVITATIONS, invitiations)
    }
    commit(types.MUTATION_FETCHING_STATUS, false)
  },
  async [types.ACTION_HANDLE_INVITATION_RESPONSE] ({ dispatch }, payload) {
    await decisionsAPI.updateInvitationState(payload)
    await dispatch(types.ACTION_LOAD_INVITATIONS)
  },
}

// mutations
const mutations = {
  [commonTypes.MUTATION_RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.MUTATION_NEW_DECISION_DIALOG] (state, dialogOpen) {
    state.newDialogOpen = dialogOpen
  },
  [types.MUTATION_SET_NEW_DECISION_LINKED_OPTION] (state, newDecisionLinkedOption) {
    state.newDecisionLinkedOption = newDecisionLinkedOption
  },
  [types.MUTATION_SET_DECISIONS] (state, decisions) {
    state.decisions = decisions
  },
  [types.MUTATION_ADD_DECISION] (state, decision) {
    const existingDecision = state.decisions.find(next => next.id === decision.id)
    if (!existingDecision) { 
      state.decisions.push(decision)
    }
  },
  [types.MUTATION_REMOVE_DECISION] (state, decisionId) {
    const decisionIndex = state.decisions.findIndex(next => next.id === decisionId)
    if (decisionIndex > -1) {
      state.decisions.splice(decisionIndex, 1)
    }
  },
  [types.MUTATION_UPDATE_DECISION] (state, decisionData) {
    const decisionIndex = state.decisions.findIndex(next => next.id === decisionData.id)
    if (decisionIndex > -1) {
      Object.keys(decisionData.updates).forEach(key => {
        state.decisions[decisionIndex][key] = decisionData.updates[key]
      })
    }
  },
  [types.MUTATION_FETCHING_STATUS] (state, status) {
    state.fetchingStatus = status
  },
  [types.MUTATION_LOADING_USERS_DECISIONS] (state, status) {
    state.laodingUsersDecision = status
  },
  [types.MUTATION_LOADING_SHARED_DECISIONS] (state, status) {
    state.laodingSharedDecision = status
  },
  [types.MUTATION_SET_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
    if (state.dataUnSubscribeCallback) {
      state.dataUnSubscribeCallback()
      state.dataUnSubscribeCallback = null
    }
    state.dataUnSubscribeCallback = newCallback
  },
  [types.MUTATION_SET_SHARED_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
    if (state.sharedDataUnSubscribeCallback) {
      state.sharedDataUnSubscribeCallback()
      state.sharedDataUnSubscribeCallback = null
    }
    state.sharedDataUnSubscribeCallback = newCallback
  },
  [types.MUTATION_SET_ASSISTED_DECISIONS_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
    if (state.assistedDecisionsUnsubscribeCallback) {
      state.assistedDecisionsUnsubscribeCallback()
      state.assistedDecisionsUnsubscribeCallback = null
    }
    state.assistedDecisionsUnsubscribeCallback = newCallback
  },
  [types.MUTATION_SET_INVITATIONS] (state, invitationsList) {
    state.invitationsList = invitationsList
  },
  [types.MUTATION_SET_ASSISTED_DECISIONS] (state, assistedDecisions) {
    state.assistedDecisions = assistedDecisions
  },
  [types.MUTATION_ADD_SHARED_OWNER] (state, ownerDetails) {
    state.sharedOwners[ownerDetails.nameSlug] = ownerDetails.uid
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
