<template>
  <v-skeleton-loader
      v-if="loading"
      type="image"
      width="240"
      class="ma-0 pa-0"
    ></v-skeleton-loader>
  <v-card
    v-else
    :max-width="240"
    outlined
    class="notification-card"
  >
    <v-card-text class="invite-text">
      <h3 class="mb-2 d-flex align-center">
        <v-icon size="sm" class="mr-2">mdi-message</v-icon>
        You have an invitation
      </h3>
      <div class="mb-2" v-if="ownersDetails">{{ownersDetails.name}} added you to the decision <b>{{ pikleData.name }}</b></div>
      <div class="mb-2" v-else>Join thier board <b>{{ pikleData.name }}</b></div>
      <!-- <div class="text-caption mb-2">
        {{ pikleData.appDomain }}
      </div> -->
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="success"
        :disabled="responding"
        :loading="respondingTrue"
        @click="handleInvitationResponse(true)"
      >
        Accept
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        :disabled="responding"
        :loading="respondingFalse"
        @click="handleInvitationResponse(false)"
      >
        Dismiss
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import AuthUtil from '../../authUtil'
import userAPI from '../../api/user'

export default {
  name: 'PikleInvitationCard',
  props: {
    pikleData: Object
  },
  data () {
    return {
      ownersDetails: null,
      respondingTrue: false,
      respondingFalse: false,
      loading: false
    }
  },
  emits: ['onResponse'],
  computed: {
    currentUserOwnsPikle () {
      return AuthUtil.auth && AuthUtil.auth.currentUser && AuthUtil.auth.currentUser.uid === this.pikleData.owner
    },
    responding () {
      return this.respondingTrue || this.respondingFalse
    },
    ownerInitials () {
      let firstChar = "?"
      let secondChar = ""
      const theName = this.ownersDetails ? this.ownersDetails.name : null
      if (theName) {
        firstChar = theName.charAt(0)
        const displayNameWords = theName.split(' ')
        if (displayNameWords && displayNameWords.length > 1) {
          secondChar = displayNameWords[1].charAt(0)
        }
      }

      return `${firstChar}${secondChar}`.toUpperCase()
    }
  },
  mounted: async function () {
    if (!this.currentUserOwnsPikle) {
      this.loading = true
      this.ownersDetails = await userAPI.getUsersPublicDetails(this.pikleData.owner)
      this.loading = false
    }
  },
  methods: {
    handleInvitationResponse: function (accepted) {
      this.respondingTrue = accepted
      this.respondingFalse = !accepted
      this.$emit('onResponse', {decisionId: this.pikleData.id, accepted: accepted})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-outer {
  position: relative;
  padding-bottom: 50px;
  &:hover {
    background-color: #EEE;
  }
}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card-owner-avatar {
  position: absolute;
  right: -10px;
  top: -10px;
}
.owners-section {
  margin-top: 10px;
  min-height: 20px;
}
</style>
